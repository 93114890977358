var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('GlobalEvents',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==78){ return null; }if(!$event.altKey){ return null; }return _vm.keymap.apply(null, arguments)}}}),_c('b-sidebar',{staticClass:"sidebarRight newQuoteSidebar",attrs:{"type":"is-light","fullheight":true,"overlay":true,"right":true},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"topCloseSideBar is-flex pr-5 p-2"},[_c('a',{staticClass:"is-flex color-8",on:{"click":function($event){_vm.open = false}}},[_c('IconChevronLeft',{staticClass:"icon is-small mr-2"}),_c('span',{staticClass:"color-4 has-text-weight-medium"},[_vm._v("New Quote")])],1),_c('a',{staticClass:"color-8 sideBarCloseButton",on:{"click":function($event){_vm.open = false}}},[_c('IconClose',{staticClass:"icon is-small"})],1)]),_c('div',{staticClass:"p-5 SideBarBox"},[_c('p',[_vm._v("Congratulations! Select one of the options to start creating it.")]),_c('div',{class:_vm.focusedGuideBoxClass('newQuoteSidebarDiv mt-5 p-3', 2)},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-navbar-item',{staticClass:"p-0",attrs:{"tag":"router-link","to":{
                path: _vm.constants.paths.BASIC_INFO.path,
                query: { type: 'quote' },
              },"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.NEW_QUOTE_BUTTON_SCRATCH}},[_c('div',{staticClass:"new_quote__link",staticStyle:{"width":"100%"},on:{"click":function ($event) {
                    _vm.nextStep();
                    _vm.onLinkClick($event);
                  }}},[_vm._v(" SCRATCH ")])])],1),_c('div',{staticClass:"column"},[_c('b-navbar-item',{staticClass:"p-0",attrs:{"tag":"router-link","to":{
                path: _vm.constants.paths.BASIC_INFO.path,
                query: { type: _vm.constants.ITINERARY_TYPES.MODULE.type },
              },"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.NEW_QUOTE_BUTTON_MODULE}},[_c('div',{staticClass:"new_module__link w-100",on:{"click":_vm.onLinkClick}},[_vm._v(" MODULE ")])])],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
                path: _vm.constants.paths.BASIC_INFO.path,
                query: { type: _vm.constants.ITINERARY_TYPES.TEMPLATE.type },
              },"data-test-id":_vm.constants.DATA_TEST_ID_VALUES
                  .NEW_QUOTE_BUTTON_EXAMPLE_ITINERARY}},[_c('div',{staticClass:"new_itinerary__link w-100",on:{"click":_vm.onLinkClick}},[_vm._v(" EX. ITINERARY ")])])],1),_c('div',{staticClass:"column"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
                path: _vm.constants.paths.BASIC_INFO.path,
                query: { type: _vm.constants.ITINERARY_TYPES.EXPERIENCE.type },
              },"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.NEW_QUOTE_BUTTON_EXPERIENCE}},[_c('div',{staticClass:"new_experience__link w-100",on:{"click":_vm.onLinkClick}},[_vm._v(" EXPERIENCE ")])])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }